import {
  Button,
  Descriptions,
  Popconfirm,
  Result,
  Skeleton,
  Space,
} from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";

import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/dateTimeConveter";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { CollabotatorDetail } from "./type";
import useNotification from "antd/es/notification/useNotification";

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<CollabotatorDetail>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.Collabotator.Main.url());
  };

  const handleExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const userSheet = workbook.addWorksheet("협업 회사 정보");

    userSheet.columns = [
      { header: "Id", key: "id", width: 10 },
      { header: "회사명", key: "name", width: 32 },
      { header: "이메일", key: "email", width: 32 },
      { header: "연락처", key: "contact", width: 32 },
      { header: "가입일", key: "createdAt", width: 20 },
      { header: "호출 API", key: "apiUrl", width: 50 },
    ];

    userSheet.addRow({
      id: data?.id,
      name: data?.name,
      email: data?.email,
      contact: data?.contact,
      createdAt: data?.createdAt
        ? timeConverter(data.createdAt).slice(0, 10)
        : "",
      apiUrl: data?.apiUrl,
    });

    // 다운로드
    const mimeType = {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    };
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], mimeType);
    saveAs(blob, `${data?.name} 협업 회사 상세.xlsx`);
  };

  const onClickCreate = () => {
    console.log(data);
    navigate(LPPUrls.Admin.ProductBis.Create.url, {
      state: { collaborator: data as CollabotatorDetail },
    });
  };

  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.Collabotator.Update.url(id!));
  };

  const onClickDelete = () => {
    setLoading(true);
    LPPAXIOS.delete(`/admin/third-party/${id}`)
      .then((resolve) => {
        api.success({
          message: "협업 회사가 삭제되었습니다.",
          description: "협업 회사 조회 페이지로 이동합니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Collabotator.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "협업 회사 삭제 실패",
          description: error.response.data.message ?? "",
        });
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    LPPAXIOS.get<CollabotatorDetail>(`/admin/third-party/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>협업 회사 상세조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Collabotator.Main.url()}>
              협업 회사 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>협업 회사 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <Descriptions
            bordered
            column={1}
            title={
              <Space className="flex justify-between w-full">
                <Space>
                  <Button onClick={handleExcel}>엑셀 다운로드</Button>
                  <Button onClick={onClickCreate}>상품 추가</Button>
                </Space>

                <Space>
                  <Button type="primary" onClick={onClickUpdate}>
                    수정
                  </Button>
                  <Popconfirm
                    title={"협업 회사 삭제"}
                    onConfirm={onClickDelete}
                  >
                    <Button danger>삭제</Button>
                  </Popconfirm>
                </Space>
              </Space>
            }
          >
            <Descriptions.Item label="회사명">{data?.name}</Descriptions.Item>
            <Descriptions.Item label="연락처">
              {data?.contact}
            </Descriptions.Item>
            <Descriptions.Item label="이메일">{data?.email}</Descriptions.Item>

            <Descriptions.Item label="가입일자">
              {data?.createdAt ? timeConverter(data.createdAt) : ""}
            </Descriptions.Item>
            <Descriptions.Item label="API">
              {data?.apiUrl ?? "string"}
            </Descriptions.Item>
          </Descriptions>
        )}
      </HContent>
    </>
  );
}
