import { Breadcrumb, Button, Form, Input, Result } from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import axios, { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import TextArea from "antd/es/input/TextArea";
import Title from "antd/es/typography/Title";

export default function UpdatePage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const response = await LPPAXIOS.put(`/admin/notice/${id}`, formValue);
      api.success({
        message: "공지사항 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Notice.Detail.url(id!), { replace: true });
      }, 500);
    } catch (error) {
      api.error({
        message: "공지사항 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/notice/${id}`)
      .then((resolve) => {
        form.setFieldsValue({
          ...resolve.data,
          content: resolve.data.content,
        });
      })
      .catch((error) => {
        // if (axios.isAxiosError(error)) {
        //   return setError(error);
        // }
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null) return <Result status={404} title={"404"} />;
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>공지사항 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Notice.Main.url()}>공지사항 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>공지사항 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Form.Item
            name="title"
            label="공지제목"
            rules={[
              {
                required: true,
                message: "공지 제목을 입력하세요.",
              },
              { max: 40, message: "최대 40자까지 작성가능합니다." },
            ]}
          >
            <Input maxLength={40} />
          </Form.Item>
          <Form.Item
            name="content"
            label="공지내용"
            rules={[
              {
                required: true,
                message: "공지내용 입력하세요.",
              },
              { max: 5000, message: "최대 5000자까지 작성가능합니다." },
            ]}
          >
            <TextArea maxLength={5000} showCount />
          </Form.Item>
          <Button block htmlType="submit" type="primary">
            공지사항 수정
          </Button>
        </Form>
      </HContent>
    </>
  );
}
