import {
  Descriptions,
  Result,
  Image,
  Collapse,
  Space,
  Button,
  Tag,
  Popconfirm,
} from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import useNotification from "antd/es/notification/useNotification";

import Title from "antd/es/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { TRACKDETAIL } from "./type";

const { Panel } = Collapse;

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<TRACKDETAIL>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.Sounds.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.Sounds.Update.url(id!));
  };
  const onClickDelete = () => {
    setLoading(true);
    LPPAXIOS.post(`/admin/track/delete/multiple`, {
      ids: [id],
    })
      .then((resolve) => {
        api.success({
          message: "곡이 삭제되었습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Sounds.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "곡을 삭제할 수 없습니다.",
        });
      })
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<TRACKDETAIL>(`/admin/track/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>사운드 관리</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Sounds.Main.url()}>사운드</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>사운드 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Space className="flex justify-end w-full">
          <Button onClick={onClickUpdate}>수정</Button>
          <Popconfirm
            title="사운드 삭제"
            description="해당 사운드를 삭제하시겠습니까?"
            onConfirm={onClickDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>삭제</Button>
          </Popconfirm>
        </Space>
        <Image
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.artwork}`}
          width={300}
        />
        <Image
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.thumbnail}`}
          width={300}
        />
        <audio
          controls
          src={`${process.env.REACT_APP_IMG_ENDPOINT}/${data?.url}`}
        />
        <Descriptions column={2} bordered>
          <Descriptions.Item label="음원 플레이 시간">
            {data?.duration}
          </Descriptions.Item>
          <Descriptions.Item label="음원 타입">
            {data?.isFree ? "무료" : "구독음원"}
          </Descriptions.Item>
          <Descriptions.Item label="음원 좋아요 수">
            {data?.likeCount}
          </Descriptions.Item>
          <Descriptions.Item label="음원 생성일">
            {data?.createdAt}
          </Descriptions.Item>
        </Descriptions>
        <Collapse defaultActiveKey={["ko"]} collapsible="header">
          <Panel header="한국어" key="ko">
            <p>이름:{data?.title.ko}</p>
            <p>소개글:{data?.description.ko}</p>
            <p>
              태그:{" "}
              {data?.tag.ko.map((item, index) => (
                <Tag key={`ko-tag-${index}`}>#{item}</Tag>
              ))}
            </p>
          </Panel>
          <Panel header="영어" key="en">
            <p>이름:{data?.title.en}</p>
            <p>소개글:{data?.description.en}</p>
            <p>
              태그:{" "}
              {data?.tag.en.map((item, index) => (
                <Tag key={`en-tag-${index}`}>#{item}</Tag>
              ))}
            </p>
          </Panel>
        </Collapse>
      </HContent>
    </>
  );
}
