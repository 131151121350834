export interface TableOrder {
  id: string;
  amount: number;
  orderDate: string;
  thirdPartyId: number;
  thirdPartyProductId: number;
  thirdPartyName: string;
  thirdPartyProductName: string;
  status: STATUS;
  isFirstYn: string;
  mberNo: string;
  mberNm: string;
  mberHpNo: string;
  couponId: number;
  updateDate: string;
  cancelDate?: string;
  cancelReason?: string;
  cancellationExpiresAt: string;
}
export interface RESPONSETABLE<T> {
  list: T[];
  total: number;
}

export interface DetailOrder {
  orderDate: string;
  orderNumber: number;
  userId: number;
  totalPrice: number;
  totalDiscount: number;
  totalPayment: number;
  paymentMethod: string;
  nickname: string;
  orderProductId: number;
  orderProductName: string;
  impUid: string;
  state: string;
}
export enum STATUS {
  PAID = "paid",
  COMFIRMED = "confirmed",
  CANCELLED = "cancelled",
  REFUNDED = "refunded",
}
