import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import Pagination, { PaginationProps } from "antd/lib/pagination";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
// import Button from "antd/lib/button";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Header from "../../../../components/common/Header";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";

import { timeConverter } from "../../../../utils/dateTimeConveter";
import { preventSpaceBar } from "../../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../../utils/paramsFilter";
// import { Workbook } from "exceljs";
// import { saveAs } from "file-saver";
import { CollaborateProductTable, RESPONSELIST } from "./type";
import { Tag } from "antd";

const { Title } = Typography;

export default function BisTablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [data, setData] = useState<CollaborateProductTable[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<CollaborateProductTable> = [
    // {
    //   title: "회사명",
    //   dataIndex: "collaboratorName",
    // },
    {
      title: "상품",
      dataIndex: "nameKo",
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "nameKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "가격",
      dataIndex: "price",
      sorter: true,
      defaultSortOrder:
        sort === "price" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "구독기간(일)",
      dataIndex: "duration",
      sorter: true,
      defaultSortOrder:
        sort === "duration" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: "사용,등록 가능 기간(일)",
      dataIndex: "activeDuration",
    },
    {
      title: "취소,환불 가능 기간(일)",
      dataIndex: "cancelDuration",
      render: (_, record) => {
        if (record.cancelDuration === 0) {
          return <Tag color="red">환불 불가 상품</Tag>;
        }
        return record.cancelDuration;
      },
    },

    {
      title: "상태",
      dataIndex: "enable",
      render: (_, record) => {
        if (record.enable) {
          return <Tag color="green">활성</Tag>;
        } else {
          return <Tag color="red">비활성화</Tag>;
        }
      },
    },
    {
      title: "발행일자",
      dataIndex: "createdAt",
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
    },
  ];

  // const handleExcel = async () => {
  //   const workbook = new Workbook();
  //   const userSheet = workbook.addWorksheet("B2B 상품 리스트 조회");

  //   userSheet.columns = [
  //     { header: "회사명", key: "name", width: 32 },
  //     { header: "발행일자", key: "createdAt", width: 20 },
  //     { header: "구독기간", key: "duration", width: 32 },
  //     { header: "상품상태", key: "enable", width: 32 },
  //   ];
  //   data.forEach((item, idx) => {
  //     userSheet.addRow({
  //       name: item.collaboratorName,
  //       createdAt: timeConverter(item.createdAt).slice(0, 10),
  //       duration: item.duration,
  //       enable: item.enable ? "활성" : "비활성",
  //     });
  //   });
  //   // 다운로드
  //   const mimeType = {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //   };
  //   const buffer = await workbook.xlsx.writeBuffer();
  //   const blob = new Blob([buffer], mimeType);
  //   saveAs(blob, `B2B 상품 리스트 ${currentPage}페이지.xlsx`);
  // };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter.field ?? null;
    // let type =
    //   Array.isArray(filter["type"]) && filter["type"].length
    //     ? filter["type"][0]
    //     : null;

    let result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.ProductBis.Main.url(
        currentPage,
        paramsFilter({ keyword: keyword, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.ProductBis.Main.url(
        1,
        paramsFilter({
          keyword: value,
          sort: sort,
          order: order,
          type: type,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.ProductBis.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          type: type,
        })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    LPPAXIOS.get<RESPONSELIST>(
      `/admin/third-party/product?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          type: type,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, keyword]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 상품 조회</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.ProductBis.Detail.url(record.id));
              }, // click row
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>B2B 상품 리스트</Title>
                {/* <Button onClick={handleExcel}>엑셀 다운로드</Button> */}
                {/* <Button type="primary" onClick={onClickCreate}>
                  협업 회사 추가
                </Button> */}
              </Space>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="회사명 또는 상품명 검색하세요"
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
