export interface Root {
  list: TableCoupon[];
  total: number;
}

export interface TableCoupon {
  id: number;
  code: string;
  name: string;
  isUnlimited: boolean;
  type: COUPONTYPE;
  value: number;
  isEvent: boolean;
  status: string;
  createdAt: string;
  registeredAt: string | null;
  usedAt: any;
}

export enum COUPONSTATUS {
  UNASSIGNED = "unassigned",
  REGISTERED = "registered",
  USED = "used",
  CANCELLED = "cancelled",
  EXPIRED = "expired",
}
export enum COUPONTYPE {
  SUBSCRIPTION = "subscription",
  DISCOUNT = "discount",
}
