import {
  Button,
  notification,
  Pagination,
  PaginationProps,
  Popconfirm,
  Space,
  Table,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { Key, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../components/common/Header";
import { LPPAXIOS } from "../../../framework/api/core.api";

import { LPPUrls } from "../../../LPPUrls";

import { timeConverter } from "../../../utils/dateTimeConveter";
import { navFilter, urlFilter } from "../../../utils/paramsFilter";
import { TableTheme } from "./type";

const { Title } = Typography;

export default function TablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const type = params.get("type");

  const [data, setData] = useState<TableTheme[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);

  const TableColumn: ColumnsType<TableTheme> = [
    {
      title: "타이틀",
      dataIndex: "titleKo",
      render: (_, record) => record.titleKo,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "titleKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "업로드일",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt).slice(0, 10),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    {
      title: (
        <Popconfirm
          // disabled={selectedRowKeys.length === 0}
          disabled={true}
          title="배경테마 삭제"
          description="배경테마를 삭제하시겠습니까?"
          onConfirm={(e: any) => {
            handleDeleteAll();
          }}
          onCancel={(e: any) => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button
              // danger disabled={selectedRowKeys.length === 0}>
              danger
              disabled={true}>
            삭제
          </Button>
        </Popconfirm>
      ),
      render: (_, record) => (
        <>
          <Space>
            <Button
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation
                // Handle edit logic here
                handleEdit(record);
              }}
            >
              수정
            </Button>
            <Popconfirm
              title="배경테마 삭제"
              description="배경테마를 삭제하시겠습니까?"
              onConfirm={(e: any) => {
                e.stopPropagation(); // Stop event propagation
                handleDelete(record);
              }}
              onCancel={(e: any) => {
                e.stopPropagation(); // Stop event propagation
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                danger
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                삭제
              </Button>
            </Popconfirm>
          </Space>
        </>
      ),
    },
  ];

  const handleEdit = (record: TableTheme) => {
    // Handle edit action here
    console.log("Edit clicked for record:", record);
    navigate(LPPUrls.Admin.BackgroundThemeManagement.Update.url(record.id));
  };

  /** 개별 삭제 */
  const handleDelete = (record: TableTheme) => {
    // Handle delete action here
    // LPPAXIOS.post(`/admin/theme/delete`, {
    //   ids: [record.id],
    // })
    LPPAXIOS.delete(`/admin/theme/${record.id}`)
      .then((resolve) => {
        api.success({
          message: "배경테마가 삭제되었습니다.",
        });
        setData((prev) => prev.filter((item) => item.id !== record.id));
      })
      .catch((error) => {
        api.error({
          message: "배경테마 삭제 실패",
        });
      });
  };

  const handleDeleteAll = () => {
    // Handle delete action here
    LPPAXIOS.post(`/admin/theme/delete`, {
      ids: selectedRowKeys,
    })
      .then((resolve) => {
        api.success({
          message: "배경테마가 삭제되었습니다.",
        });
        window.location.reload();
      })
      .catch((error) => {
        api.error({
          message: "배경테마 삭제 실패",
        });
      });
  };

  const onClickCreateSound = () => {
    navigate(LPPUrls.Admin.BackgroundThemeManagement.Create.pathName);
  };

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let type =
      Array.isArray(filter["type"]) && filter["type"].length
        ? filter["type"][0]
        : null;

    handleChangeSort(sort, order, type);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    type: null | string
  ) => {
    navigate(
      LPPUrls.Admin.BackgroundThemeManagement.Main.url(
        currentPage,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.BackgroundThemeManagement.Main.url(
        1,
        navFilter({ keyword: value, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.BackgroundThemeManagement.Main.url(
        page,
        navFilter({ keyword: keyword, sort: sort, order: order, type: type })
      )
    );
    setCurrentPage(page);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/theme`,
        page: currentPage,
        perPage: 25,
        keyword: keyword,
        sort: sort,
        order: order,
        type: type,
      })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error: { code: any; message: any }) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, type, keyword]);

  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-between py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>배경테마</Title>
        <Button onClick={onClickCreateSound}>배경테마 생성</Button>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(
                  LPPUrls.Admin.BackgroundThemeManagement.Detail.url(record.id)
                );
              }, // click row
            };
          }}
          rowSelection={{
            selectedRowKeys,
            onChange: (selectedKeys) => {
              setSelectedRowKeys(selectedKeys);
            },
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Space>
                <Title level={4}>배경테마 리스트</Title>
              </Space>
              {/* <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="사운드 타이틀을 검색하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              /> */}
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
