import {
  Result,
  Space,
  Button,
  Divider,
  Typography,
  Dropdown,
  MenuProps,
  Popconfirm,
  Skeleton,
} from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import useNotification from "antd/es/notification/useNotification";
import { MoreOutlined } from "@ant-design/icons";

import Title from "antd/es/typography/Title";
import axios, { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import Header from "../../../components/common/Header";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";
import { timeConverter } from "../../../utils/dateTimeConveter";
import { NoticeDetail } from "./type";

export default function DetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<NoticeDetail>();

  const onClickBack = () => {
    navigate(LPPUrls.Admin.Notice.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.Notice.Update.url(id!));
  };
  const onClickDelete = () => {
    LPPAXIOS.delete(`/admin/notice/${id}`)
      .then(() => {
        api.success({
          message: "공지사항 삭제 완료.",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useLayoutEffect(() => {
    // setLoading(true);
    LPPAXIOS.get<NoticeDetail>(`/admin/notice/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <Typography.Text onClick={onClickUpdate}>수정</Typography.Text>,
    },
    {
      key: "2",
      label: <Typography.Text onClick={onClickDelete}>삭제</Typography.Text>,
    },
  ];
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>공지사항 관리</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Notice.Main.url()}>공지사항</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>공지사항 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Space className="flex justify-end w-full">
              <Button onClick={onClickUpdate}>수정</Button>
              <Popconfirm
                title="공지사항 삭제"
                description="공지사항을 삭제하시겠습니까?"
                onConfirm={onClickDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>삭제</Button>
              </Popconfirm>
            </Space>

            <Space direction="vertical">
              <Typography.Text>작성자: {data?.writer}</Typography.Text>
              <Typography.Text>
                작성시간: {data?.createdAt ? timeConverter(data.createdAt) : ""}
              </Typography.Text>
            </Space>
            <Title level={4}>공지제목: {data?.title}</Title>
            <Divider />
            <Typography>{data?.content}</Typography>
          </>
        )}
      </HContent>
    </>
  );
}
