import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  UploadProps,
  message,
  Card,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import axios, { AxiosError } from "axios";
import {useEffect, useLayoutEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";
import { categoryListData, findCategory } from "../../../utils/data";

import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { RcFile } from "antd/es/upload";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import TextArea from "antd/es/input/TextArea";
import {TableCategory} from "../Category/type";

export default function CreatePage() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  // const [data, setData] = useState<TRACKDETAIL>();
  const [artwork, setArtwork] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<TableCategory[]>([]);

  const handleUpdateTrack = async (formValue: any) => {
    setLoading(true);
    try {
      const artworkImage =
        (await handleUploadCompMultiFilesV2(artwork, "image", null)) ?? [];

      const {artwork: tempArtwork, ...rest} = formValue;
      const result = {
        ...rest,
        image: artworkImage[0],
        viewYn: "Y",
        dlYn: "N",
      };
      console.log(result);
      // return;

      const response = await LPPAXIOS.post(`/admin/doctor`, result);
      // const response2 = await LPPAXIOS.post(`/admin/doctor/category`, {
      //   doctorId: response.data.doctorId,
      //   categoryId: formValue.categoryId,
      // });
      api.success({
        message: "참여 의료진 생성이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.ParticipantManagement.Main.url(), {
          replace: true,
        });
      }, 500);
    } catch (error) {
      api.error({
        message: "참여 의료진 생성이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newupdatedFileList);
  };

  useEffect(() => {
    form.setFieldsValue({
      categoryId: 7,
      isFree: false,
    });
  }, []);

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get(`/admin/category`)
        .then((res) => {
          setCategories(res.data.list);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          console.log(categories);
          setLoading(false)
        });
  }, []);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>참여의료진 생성</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ParticipantManagement.Main.url()}>
              참여 의료진 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>참여의료진 생성</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleUpdateTrack}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item
              name="koName"
              label="이름"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koDepartment"
              label="전공"
              rules={[
                {
                  required: true,
                  message: "전공을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koPosition"
              label="직급"
              rules={[
                {
                  required: true,
                  message: "직급을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="koDescription"
              label="소개"
              rules={[
                {
                  required: true,
                  message: "소개내용을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="koHistory"
              label="이력"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Card>

          <Card title="영어">
            <Form.Item
              name="enName"
              label="이름"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enDepartment"
              label="전공"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enPosition"
              label="직급"
              rules={[
                {
                  required: true,
                  message: "직급을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="enDescription"
              label="소개"
              rules={[
                {
                  required: true,
                  message: "소개내용을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              name="enHistory"
              label="이력"
              rules={[
                {
                  required: true,
                  message: "이름을 입력하세요.",
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Card>

          <Divider />
          <Form.Item name="categoryIds" label="담당 카테고리">
            {/*<Select mode="multiple" options={categoryListData} />*/}
            <Select mode="multiple" options={categories.map(c => ({
              label: c.categoryKo,
              value: c.id
            }))} />
          </Form.Item>

          <Form.Item
            name="artwork"
            label="프로필 이미지"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "참여의료진 프로필등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              beforeUpload={(file: RcFile) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  message.error("Image must smaller than 2MB!");
                }
                return isLt2M;
              }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              {/* <UploadButton /> */}
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          <Button block htmlType="submit" type="primary">
            참여의료진 생성
          </Button>
        </Form>
      </HContent>
    </>
  );
}

function UploadButton() {
  return (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
}
