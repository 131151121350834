import { Skeleton } from "antd";
import Breadcrumb from "antd/es/breadcrumb/Breadcrumb";
import Descriptions from "antd/es/descriptions";
import useNotification from "antd/es/notification/useNotification";
import Result from "antd/es/result";

import Title from "antd/es/typography/Title";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";
import Space from "antd/lib/space";

import Tag from "antd/lib/tag";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../../components/common/HContent";
import Header from "../../../../components/common/Header";

import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import { DetailProduct } from "./type";

export default function CusDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [api, contextHolder] = useNotification();

  const [data, setData] = useState<DetailProduct>();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.ProductCus.Main.url());
  };
  const onClickUpdate = () => {
    navigate(LPPUrls.Admin.ProductCus.Update.url(id!));
  };
  const onClickDelete = () => {
    LPPAXIOS.delete(`/admin/product/${id}`)
      .then((resolve) => {
        api.success({
          message: "상품이 삭제되었습니다.",
        });
      })
      .catch((error) => {
        api.error({
          message: "상품 삭제 실패",
        });
      });
  };
  useLayoutEffect(() => {
    LPPAXIOS.get<DetailProduct>(`/admin/product/${id}`)
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}

      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2C 상품 상세조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ProductCus.Main.url()}>B2C 상품 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>B2C 상품 상세페이지</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active={loading} />
        ) : (
          <>
            <Descriptions bordered column={1}>
              <Descriptions.Item label="상품명">
                {data?.name.ko}
              </Descriptions.Item>
              <Descriptions.Item label="상품설명">
                {data?.description.ko}
              </Descriptions.Item>
              <Descriptions.Item label="상품타입">
                {data?.isGift ? (
                  <Tag color="orange">선물권</Tag>
                ) : data?.type === 'subscription' ? (
                  <Tag color="green">구독권</Tag>
                ) : (
                  <Tag color="geekblue">이용권</Tag>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="이용기간">
                {data?.duration}일
              </Descriptions.Item>
              <Descriptions.Item label="원화 가격">
                {(data?.price.krw || 0).toLocaleString()}원
              </Descriptions.Item>
              <Descriptions.Item label="달러 가격">
                {(data?.price.usd || 0).toLocaleString()}$
              </Descriptions.Item>
              {/*<Descriptions.Item label="다음 결제 예정 상품">*/}
              {/*  {data?.nextPaymentProductName}*/}
              {/*</Descriptions.Item>*/}
              <Descriptions.Item label="발급날짜">
                {data?.createdAt ? timeConverter(data.createdAt) : ""}
              </Descriptions.Item>
            </Descriptions>
            <Space className="flex justify-end w-full">
              <Button onClick={onClickUpdate}>수정</Button>
              <Popconfirm
                title="상품 삭제"
                description="해당 상품을 삭제하시겠습니까?"
                onConfirm={onClickDelete}
                okText="Yes"
                cancelText="No"
              >
                <Button danger>삭제</Button>
              </Popconfirm>
            </Space>
          </>
        )}
      </HContent>
    </>
  );
}
