import { Breadcrumb, Button, Form, Input } from "antd";

import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Title from "antd/es/typography/Title";

import { LPPAXIOS } from "../../../framework/api/core.api";
import { LoadingSpin } from "../../../components/common/Loading";
import Header from "../../../components/common/Header";
import { LPPUrls } from "../../../LPPUrls";
import { HContent } from "../../../components/common/HContent";
import { formatPhoneNumber } from "../../../utils/formatPhoneNumber";
import { CollabotatorDetail } from "./type";

interface FormValue {
  name: string;
  email: string;
  contact: string;
  apiUrl: string;
}
export default function UpdatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const onSubmitCreate = (value: FormValue) => {
    setLoading(true);

    LPPAXIOS.put(`/admin/third-party/${id}`, {
      ...value,
      contact: formatPhoneNumber(value.contact),
    })
      .then((resolve) => {
        api.success({
          message: "협력 업체가 추가되었습니다.",
          description: "협업 회사 리스트 페이지로 이동합니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.Collabotator.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "협력 업체 추가 실패",
          description: error.response.data.message ?? "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    LPPAXIOS.get<CollabotatorDetail>(`/admin/third-party/${id}`)
      .then((resolve) => {
        form.setFieldsValue({
          ...resolve.data,
          contact: resolve.data.contact.replaceAll("-", ""),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>협업 회사 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Collabotator.Main.url()}>
              협업 회사 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>협업 회사 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={onSubmitCreate}>
          <Form.Item
            label="회사명"
            name="name"
            rules={[
              {
                required: true,
                message: "회사명을 입력하세요.",
              },
            ]}
          >
            <Input placeholder="회사명을 입력하세요." />
          </Form.Item>
          <Form.Item
            label="연락처"
            name="contact"
            rules={[
              {
                required: true,
                message: "협업 회사의 연락처를 입력하세요.",
              },
            ]}
          >
            <Input maxLength={13} placeholder="연락처(숫자만) 입력하세요." />
          </Form.Item>

          <Form.Item
            label="이메일"
            name="email"
            rules={[
              {
                required: true,
                message: "협력 회사의 이메일을 입력하세요.",
              },
            ]}
          >
            <Input type="email" placeholder="ex) placeholder@gmail.com" />
          </Form.Item>
          <Form.Item
            label="API 주소"
            name="apiUrl"
            extra="호출받을 API url입니다. 선택사항입니다."
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              협업 회사 수정
            </Button>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
}
