import {
  Button,
  Input,
  notification,
  Pagination,
  PaginationProps,
  Table,
  Tag,
  Typography,
} from "antd";

import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import axios from "axios";
import { useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../../../components/common/Header";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import { preventSpaceBar } from "../../../../utils/inputOnlyNumber";
import {
  filterNonEmptyProperties,
  paramsFilter,
} from "../../../../utils/paramsFilter";
import { TableProduct, RESPONSETABLE } from "./type";

const { Title } = Typography;

export default function CusTablePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const keyword = params.get("keyword");
  const order = params.get("order");
  const sort = params.get("sort");
  const isSubscriptionProduct = params.get("isSubscriptionProduct");
  const type = params.get("type");

  const [data, setData] = useState<TableProduct[]>([]);
  const [currentPage, setCurrentPage] = useState(Number(params.get("page")));
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const TableColumn: ColumnsType<TableProduct> = [
    {
      title: "상품이름",
      dataIndex: "nameKo",
      sorter: true,
      defaultSortOrder:
        sort === "nameKo" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    // {
    //   title: "원화 가격",
    //   dataIndex: "price",
    //   sorter: true,
    //   render: (_, record) => `${record.price.toLocaleString()}원`,
    //   defaultSortOrder:
    //     sort === "price" ? (order === "ASC" ? "ascend" : "descend") : null,
    // },
    {
      title: "원화 가격",
      dataIndex: "price",
      sorter: true,
      render: (_, record) => `${record.price.krw.toLocaleString()}원`,
      defaultSortOrder:
        sort === "price" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    // {
    //   title: "달러 가격",
    //   dataIndex: "priceUSD",
    //   render: (_, record) => `${record.priceUSD.toLocaleString()}$`,
    //   sorter: true,
    //   defaultSortOrder:
    //     sort === "priceUSD" ? (order === "ASC" ? "ascend" : "descend") : null,
    // },
    {
      title: "달러 가격",
      dataIndex: "priceUSD",
      render: (_, record) => `${record.price.usd.toLocaleString()}$`,
      sorter: true,
      defaultSortOrder:
        sort === "priceUSD" ? (order === "ASC" ? "ascend" : "descend") : null,
    },

    {
      title: "발급날짜",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        sort === "createdAt" ? (order === "ASC" ? "ascend" : "descend") : null,
    },
    // {
    //   title: "상품타입",
    //   dataIndex: "isSubscriptionProduct",
    //   width: "10%",
    //   render: (_, record) => {
    //     if (record.isSubscriptionProduct === true) {
    //       return <Tag color="green">구독권</Tag>;
    //     }
    //     if (record.isSubscriptionProduct === false) {
    //       if (record.isGiftProduct === false) {
    //         return <Tag color="geekblue">이용권</Tag>;
    //       }
    //       if (record.isGiftProduct) {
    //         return <Tag color="orange">선물권</Tag>;
    //       }
    //     }
    //   },
    //   filters: [
    //     {
    //       text: <Tag color="green">구독권</Tag>,
    //       value: true,
    //     },
    //     {
    //       text: <Tag color="purple">이용권 & 선물권</Tag>,
    //       value: false,
    //     },
    //   ],
    //   filterMultiple: false,
    //   defaultFilteredValue:
    //     isSubscriptionProduct === null ? [] : [isSubscriptionProduct],
    // },
    {
      title: "상품타입",
      dataIndex: "type",
      width: "10%",
      render: (_, record) => {
        if (record.isGift) {
          return <Tag color="orange">선물권</Tag>
        }
        if (record.type === 'subscription') {
          return <Tag color="green">구독권</Tag>;
        }
        return <Tag color="geekblue">이용권</Tag>;
      },
      filters: [
        {
          text: <Tag color="green">구독권</Tag>,
          value: 'subscription',
        },
        {
          text: <Tag color="purple">이용권 & 선물권</Tag>,
          value: 'coupon',
        },
      ],
      filterMultiple: false,
      defaultFilteredValue: [],
    }
    // {
    //   title: "다음 결제 예약 상품",
    //   dataIndex: "nextPaymentProductName",
    //   render: (_, record) =>
    //     record.isSubscriptionProduct ? record.nextPaymentProductName : "",
    // },
  ];

  const handleTableChange = (value: any, filter: any, sorter: any) => {
    console.log(filter)
    let order =
      sorter.order === null ? null : sorter.order === "ascend" ? "ASC" : "DESC";
    let sort = sorter && sorter.field ? sorter.field : null;

    let result = filterNonEmptyProperties(filter);

    handleChangeSort(sort, order, result);
  };

  const handleChangeSort = (
    sort: null | string,
    order: null | string,
    result: { [key: string]: string | null }
  ) => {
    navigate(
      LPPUrls.Admin.ProductCus.Main.url(
        currentPage,
        paramsFilter({ keyword: keyword, sort: sort, order: order, ...result })
      )
    );
    setCurrentPage(currentPage);
  };

  const handleOnSearchFilter = (value: string) => {
    navigate(
      LPPUrls.Admin.ProductCus.Main.url(
        1,
        paramsFilter({
          keyword: value,
          sort: sort,
          order: order,
          isSubscriptionProduct: isSubscriptionProduct,
        })
      )
    );
    setCurrentPage(1);
  };
  const handleOnChangePage: PaginationProps["onChange"] = (page) => {
    navigate(
      LPPUrls.Admin.ProductCus.Main.url(
        page,
        paramsFilter({
          keyword: keyword,
          sort: sort,
          order: order,
          isSubscriptionProduct: isSubscriptionProduct,
        })
      )
    );
    setCurrentPage(page);
  };

  const onClickCreate = () => {
    navigate(LPPUrls.Admin.ProductCus.Create.pathName);
  };

  useLayoutEffect(() => {
    setLoading(true);
    LPPAXIOS.get<RESPONSETABLE<TableProduct>>(
      `/admin/product?` +
        paramsFilter({
          page: currentPage,
          perPage: 25,
          keyword: keyword,
          sort: sort,
          order: order,
          isSubscriptionProduct: isSubscriptionProduct,
          type,
        })
    )
      .then((res) => {
        setData(res.data.list);
        setTotal(res.data.total);
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          api.error({
            message: `Notification ${error.code}`,
            description: `${error.message}`,
          });
        }
      })
      .finally(() => setLoading(false));
  }, [api, currentPage, order, sort, isSubscriptionProduct, keyword, type]);
  return (
    <>
      {contextHolder}
      <Header className="flex items-center justify-start py-1 my-2 mb-10 drop-shadow-sm">
        <Title level={4}>B2C 상품 조회</Title>
      </Header>
      <Content className="m-2 bg-white">
        <Table
          rowClassName={() => "cursor-pointer"}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={data}
          columns={TableColumn}
          onChange={(val: any, filter: any, sorter: any, extra: any) => {
            handleTableChange(val, filter, sorter);
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                navigate(LPPUrls.Admin.ProductCus.Detail.url(record.id));
              }, // click row
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="상품명을 입력하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
              <Button onClick={onClickCreate}>상품 생성</Button>
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={handleOnChangePage}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />
      </Content>
    </>
  );
}
