import { Card, Divider, Skeleton, Space, Typography } from "antd";
import Header from "../../../../components/common/Header";
import Breadcrumb from "antd/lib/breadcrumb/Breadcrumb";
import Button from "antd/lib/button";
import Descriptions from "antd/lib/descriptions";
import Result from "antd/lib/result";
import Title from "antd/lib/typography/Title";
import { AxiosError } from "axios";
import { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../../components/common/HContent";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { DetailOrder } from "./type";
import useNotification from "antd/lib/notification/useNotification";
import Popconfirm from "antd/lib/popconfirm";
import Tag from "antd/lib/tag";

export default function BisDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DetailOrder>();
  const [error, setError] = useState<AxiosError | null>(null);
  const [api, contextHolder] = useNotification();
  const onClickBack = () => {
    navigate(LPPUrls.Admin.SettlementPaymentBis.Main.url());
  };

  const onClickPaymentCancel = () => {
    LPPAXIOS.post(`/admin/payment/orderNumber/`, {
      orderNumber: id,
    })
      .then((resolve) => {
        api.success({
          message: "결제가 취소되었습니다.",
        });
        setData((prev) => {
          return { ...prev, status: "결제 취소" } as DetailOrder;
        });
      })
      .catch((error) => {
        api.error({
          message: "결제 취소 실패",
          description: error.reponse.data.message,
        });
      });
  };
  useLayoutEffect(() => {
    LPPAXIOS.get<DetailOrder>(
      `/admin/third-party/order/${id}`
    )
      .then((resolve) => {
        setData(resolve.data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response?.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );

  if (error !== null && error.response?.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 주문 상세 조회</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.SettlementPaymentBis.Main.url()}>
              B2B 주문 조회
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>B2B 주문 상세 조회</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        {loading ? (
          <Skeleton active />
        ) : (
          <>
            <Descriptions title="주문 내역" column={2}>
              <Descriptions.Item label="주문번호">
                {data?.orderNumber}
              </Descriptions.Item>

              <Descriptions.Item label="결제 시간">
                {data?.orderDate}
              </Descriptions.Item>
              <Descriptions.Item label="구매한 상품">
                {data?.orderProductName}
              </Descriptions.Item>
              <Descriptions.Item label="구매자 닉네임">
                {data?.nickname}
              </Descriptions.Item>
              <Descriptions.Item label="imp_Uid">
                {data?.impUid}
              </Descriptions.Item>
              <Descriptions.Item label="결제 수단">
                {data?.paymentMethod}
              </Descriptions.Item>
              <Descriptions.Item label="결제 상태">
                <Tag>{data?.state}</Tag>
              </Descriptions.Item>
            </Descriptions>

            <Card>
              <Space className="flex justify-between ">
                <Typography.Text type="secondary">상품가격</Typography.Text>
                <Typography.Text>{data?.totalPrice || 0}원</Typography.Text>
              </Space>
              <Space className="flex justify-between ">
                <Typography.Text type="secondary">할인 금액</Typography.Text>
                <Typography.Text>
                  - {data?.totalDiscount || 0}원
                </Typography.Text>
              </Space>
              <Divider />
              <Space className="flex justify-between ">
                <Typography.Text type="secondary">결제 금액</Typography.Text>
                <Typography.Text>{data?.totalPayment || 0}원</Typography.Text>
              </Space>
            </Card>
            {data?.state !== "결제 취소" && (
              <Popconfirm
                title="결제 취소 메뉴얼"
                description="해당 주문을 취소하시겠습니까?"
                onConfirm={onClickPaymentCancel}
              >
                <Button block danger>
                  결제 취소
                </Button>
              </Popconfirm>
            )}
          </>
        )}
      </HContent>
    </>
  );
}
