import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Upload,
  UploadProps,
  message,
  Card,
  Table,
  Pagination,
  Modal,
  Space,
  Breadcrumb,
} from "antd";
import Header from "../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../components/common/HContent";
import { LoadingSpin } from "../../../components/common/Loading";
import { LPPAXIOS } from "../../../framework/api/core.api";
import { LPPUrls } from "../../../LPPUrls";

import { UploadFile } from "antd/lib/upload/interface";
import { findCategory } from "../../../utils/data";

import { UploadOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { RcFile } from "antd/es/upload";
import { handleUploadCompMultiFilesV2 } from "../../../utils/uploadImgToS3";
import { TRACKTABLE } from "../Sounds/type";
import { ColumnsType } from "antd/es/table";

import { urlFilter } from "../../../utils/paramsFilter";
import { preventSpaceBar } from "../../../utils/inputOnlyNumber";
import { NotificationInstance } from "antd/es/notification/interface";

const TableColumn: ColumnsType<TRACKTABLE> = [
  {
    title: "타이틀",
    dataIndex: "titleKo",

    ellipsis: true,
  },
  {
    title: "카테고리",
    dataIndex: "categoryKo",
  },
];

export default function UpdatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const [albumTrackList, setAlbumTrackList] = useState<TRACKTABLE[]>([]);
  const [artwork, setArtwork] = useState<UploadFile[]>([]);

  const AlbumColumn: ColumnsType<TRACKTABLE> = [
    {
      title: "타이틀",
      dataIndex: "titleKo",

      ellipsis: true,
    },
    {
      title: "카테고리",
      dataIndex: "categoryKo",
    },
    {
      title: "",
      dataIndex: "operation",
      render: (_, record) => (
        <Button onClick={() => onClickDelete(record)}>삭제</Button>
      ),
    },
  ];
  const onClickDelete = (record: TRACKTABLE) => {
    // console.log("1::", record);
    // LPPAXIOS.post(`/admin/situation/delete/track`, {
    //   situationId: id,
    //   trackIds: [record.id],
    // })
    //   .then((resolve) => {
    //     api.success({
    //       message: `${record.titleKo}곡이 카테고리에서 삭제되었습니다.`,
    //     });
    //     setAlbumTrackList(
    //       albumTrackList.filter((item) => item.id !== record.id)
    //     );
    //   })
    //   .catch((error) => {
    //     api.error({
    //       message: `카테고리에서 ${record.titleKo}곡 삭제를 실패하였습니다. `,
    //       description: error.respone.status,
    //     });
    //   });
    setAlbumTrackList((prev) => prev.filter(t => t.id !== record.id))
  };

  const onClickModal = () => {
    setOpen((prev) => !prev);
  };
  const handleCreateAlbum = async (formValue: any) => {
    setLoading(true);
    try {
      const artworkImage =
        (await handleUploadCompMultiFilesV2(
          artwork,
          "image",
          findCategory(formValue.categoryId)
        )) ?? [];

      const result = {
        ...formValue,
        artwork: artworkImage[0],
        tagKo: formValue.tagKo.join(","),
        tagEn: formValue.tagEn.join(","),
        viewYn: "Y",
        trackIds: albumTrackList.map(item => item.id)
      };
      console.log(result);
      // return;

      const response = await LPPAXIOS.put(`/admin/situation/${id}`, result);
      // const response2 = await LPPAXIOS.post(`/admin/situation/tracks`, {
      //   situationId: Number(id!),
      //   trackIds: albumTrackList.map((item) => item.id),
      // });
      api.success({
        message: "앨범 수정이 완료되었습니다.",
      });
      setTimeout(() => {
        navigate(LPPUrls.Admin.Albums.Main.url(), { replace: true });
      }, 500);
    } catch (error) {
      api.error({
        message: "앨범 수정이 실패하였습니다.",
      });
    } finally {
      setLoading(false);
    }
  };
  const startEdit = (imageList: string[], stateFunc: Function) => {
    const imageFileList = imageList;
    const arr = [];
    for (let i = 0; i < imageFileList.length; i++) {
      const previewFile: UploadFile = {
        uid: `done file ${i}`,
        name: imageFileList[i],
        status: "done",
        url: `${process.env.REACT_APP_IMG_ENDPOINT}/${imageFileList[i]}`,
      };
      arr.push(previewFile);
    }
    stateFunc(arr);
  };

  const onChangeArtWork: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    const newupdatedFileList = newFileList.map((file) => {
      if (file.status !== "done") {
        file.status = "done";
      }
      return file;
    });
    setArtwork(newupdatedFileList);
  };

  // useLayoutEffect(() => {
  //   LPPAXIOS.get(`/admin/situation/track?situationId=${id}`)
  //     .then((resolve) => {
  //       startEdit([resolve.data.artwork], setArtwork);
  //       form.setFieldsValue({
  //         ...resolve.data,
  //         tagKo: resolve.data.tagKo.split(","),
  //         tagEn: resolve.data.tagEn.split(","),
  //       });
  //     })
  //     .catch((error) => {
  //       api.error({
  //         message: "앨범 정보를 가져오지 못했습니다.",
  //       });
  //     });
  // }, [id]);

  // useEffect(() => {
  //   LPPAXIOS.get(`/admin/situation/track/list?situationId=${id}`)
  //     .then((resolve) => {
  //       setAlbumTrackList(resolve.data.list);
  //     })
  //     .catch((error) => {
  //       api.error({
  //         message: "앨범 트랙을 가져오지 못했습니다.",
  //       });
  //     });
  // }, [id]);
  useEffect(() => {
      LPPAXIOS.get(`/admin/situation/${id}`)

      .then((response) => {
        startEdit([response.data.artwork], setArtwork);
        form.setFieldsValue({
          ...response.data,
          tagKo: response.data.tagKo.split(","),
          tagEn: response.data.tagEn.split(","),
        });

        setAlbumTrackList(response.data.tracks);
      })
      .catch((error) => {
        // 오류 처리
        api.error({
          message: "데이터를 가져오는 데 실패했습니다.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>앨범 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.Albums.Main.url()}>앨범 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>앨범 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form form={form} onFinish={handleCreateAlbum}>
          <Title level={4}>언어</Title>

          <Card title="한국어">
            <Form.Item name="nameKo" label="앨범 이름">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionKo" label="소개글">
              <Input />
            </Form.Item>
            <Form.Item name="tagKo" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Card title="영어">
            <Form.Item name="nameEn" label="앨범 이름">
              <Input />
            </Form.Item>
            <Form.Item name="descriptionEn" label="소개글">
              <Input />
            </Form.Item>
            <Form.Item name="tagEn" label="태그">
              <Select mode="tags" />
            </Form.Item>
          </Card>

          <Divider />

          <Title level={4}>미디어 파일</Title>
          <Form.Item
            name="artwork"
            label="이미지 첨부파일"
            extra="앨범 이미지 등록"
            rules={[
              {
                validator(rule, value, callback) {
                  if (artwork.length > 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject();
                  }
                },
                message: "앨범 이미지 등록은 필수입니다.",
              },
            ]}
          >
            <Upload
              fileList={artwork}
              name="artwork"
              multiple={false}
              maxCount={1}
              listType="picture"
              className="upload-list-inline"
              showUploadList={{ showPreviewIcon: true }}
              beforeUpload={(file: RcFile) => {
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                  message.error("Image must smaller than 2MB!");
                }
                return isLt2M;
              }}
              customRequest={({ file, onSuccess }) => {
                const res = "Ok";
                onSuccess!(res);
              }}
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangeArtWork}
            >
              {/* <UploadButton /> */}
              <Button icon={<UploadOutlined />}>앨범이미지 선택</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="추가할 사운드"
            name="selectTrack"
            rules={[
              {
                validator(rule, value, callback) {
                  if (albumTrackList.length === 0) {
                    return Promise.reject();
                  } else {
                    return Promise.resolve();
                  }
                },
                message: "곡은 1곡이상 선택 필수입니다.",
              },
            ]}
          >
            <Table
              title={() => (
                <div className="flex items-center justify-between p-2">
                  <Button onClick={onClickModal}>사운드 추가</Button>
                </div>
              )}
              scroll={{ y: 400 }}
              loading={loading}
              pagination={false}
              rowKey={(render) => render.id}
              dataSource={albumTrackList}
              columns={AlbumColumn}
            />
          </Form.Item>

          <Button block htmlType="submit" type="primary">
            앨범 수정
          </Button>
        </Form>

        <SearchTrackModal
          id={id}
          open={open}
          api={api}
          onClickModal={onClickModal}
          setAlbumTrackList={setAlbumTrackList}
        />
      </HContent>
    </>
  );
}

interface ISearchTrackModalProps {
  id: string | undefined;
  open: boolean;
  api: NotificationInstance;
  onClickModal: () => void;
  setAlbumTrackList: React.Dispatch<React.SetStateAction<TRACKTABLE[]>>;
}

function SearchTrackModal(props: ISearchTrackModalProps) {
  const { id, open, api, onClickModal, setAlbumTrackList } = props;
  const [loading, setLoading] = useState(false);
  const [trackList, setTrackList] = useState<TRACKTABLE[]>([]);
  const [selectedTrackList, setSelectedTrackList] = useState<TRACKTABLE[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onOk = () => {
    onClickModal();

    setAlbumTrackList((prevAlbumTracks) => {
      // 이미 앨범에 있는 트랙의 ID들을 추출
      const existingTrackIds = new Set(
          prevAlbumTracks.map((track) => track.id)
      );

      // selectedTrackList에서 이미 앨범에 있는 트랙을 제외한 새로운 트랙들만 필터링
      const newTracksToAdd = selectedTrackList.filter(
          (track) => !existingTrackIds.has(track.id)
      );

      // 새로운 트랙들을 현재 앨범 트랙 리스트에 추가
      return [...prevAlbumTracks, ...newTracksToAdd];
    });
  };

  const handleOnSearchFilter = (value: string) => {
    // 왼쪽 목록에서의 검색을 처리합니다.
    setKeyword(value);
    setCurrentPage(1); // 검색할 때 페이지를 다시 설정합니다.
    fetchData(1, value);
  };

  const fetchData = (page: number, keyword: string) => {
    setLoading(true);
    LPPAXIOS.get(
      urlFilter({
        url: `/admin/track/search`,
        page: page,
        perPage: 25,
        keyword: keyword,
      })
    )
      .then((response) => {
        // 여기서는 key 속성을 각 트랙 객체에 추가해야 합니다.
        const tracksWithKeys = response.data.list.map((track: TRACKTABLE) => ({
          ...track,
          key: track.id.toString(), // Transfer 컴포넌트를 위해 key 속성을 추가합니다.
        }));
        setTrackList(tracksWithKeys);
        setTotal(response.data.total);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchData(currentPage, keyword);
  }, [currentPage, keyword]);

  // 여기에 페이지네이션 처리 함수를 추가할 수 있습니다.

  return (
    <Modal
      destroyOnClose
      open={open}
      onCancel={onClickModal}
      onOk={onOk}
      className="w-full"
    >
      <Space className="flex items-start w-full">
        <Table
          scroll={{ y: 400 }}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={trackList}
          columns={TableColumn}
          onRow={(record, _) => {
            return {
              onClick: () => {}, // click row
              onDoubleClick: () => {
                //곡 추가
                const select = trackList.find(
                  (track) => track.id === record.id
                );

                setSelectedTrackList((prev) => {
                  // 'select'가 존재하는지 여부를 통해 'select'가 존재하면 현재 상태를 그대로 반환
                  const isExist = prev.some((item) => item.id === select?.id);
                  if (isExist) {
                    // 이미 존재하면 아무것도 변경하지 않음
                    return prev;
                  } else {
                    // 존재하지 않으면 새로운 배열을 반환하면서 'select'를 추가
                    return [...prev, select!];
                  }
                });
              },
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={5}>검색결과</Title>
              <Input.Search
                defaultValue={keyword ?? ""}
                style={{ width: 300 }}
                placeholder="사운드 타이틀을 검색하세요."
                onSearch={handleOnSearchFilter}
                onInput={preventSpaceBar}
              />
            </div>
          )}
          footer={() => (
            <div className="flex justify-end">
              <Pagination
                current={currentPage}
                onChange={(page) => setCurrentPage(page)}
                pageSize={25}
                total={total}
                showSizeChanger={false}
              />
            </div>
          )}
        />

        <Table
          scroll={{ y: 400 }}
          loading={loading}
          pagination={false}
          rowKey={(render) => render.id}
          dataSource={selectedTrackList}
          columns={TableColumn}
          onRow={(record, _) => {
            return {
              onClick: () => {
                // 곡삭제
              }, // click row
              onDoubleClick: () => {
                const selectId = record.id; // 이 부분은 당신의 코드에 따라 달라질 수 있습니다.

                setSelectedTrackList((prev) => {
                  // 'selectId'에 해당하는 아이템을 제외하고 새로운 배열을 생성
                  return prev.filter((track) => track.id !== selectId);
                });
              },
            };
          }}
          title={() => (
            <div className="flex items-center justify-between p-2">
              <Title level={5}>추가할 사운드</Title>
            </div>
          )}
        />
      </Space>
    </Modal>
  );
}
