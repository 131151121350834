import Button from "antd/lib/button";
import Divider from "antd/lib/divider";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Title from "antd/es/typography/Title";
import Card from "antd/lib/card";
import Breadcrumb from "antd/lib/breadcrumb";
import Header from "../../../../components/common/Header";
import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { HContent } from "../../../../components/common/HContent";
import { LoadingSpin } from "../../../../components/common/Loading";
import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LPPUrls } from "../../../../LPPUrls";
import { Modal, Result, Space, Table, Tag, Typography } from "antd";
import Search from "antd/es/input/Search";
import { DetailProduct, RESPONSETABLE, TableProduct } from "./type";
import { ColumnsType } from "antd/es/table";
import { timeConverter } from "../../../../utils/dateTimeConveter";
import { AxiosError } from "axios";
interface ProductForm {
  descriptionEn: string;
  descriptionKo: string;
  duration: string;
  nameEn: string;
  nameKo: string;
  price: string;
  priceUSD: string;
  productType: PRODUCTTYPE;
}

enum PRODUCTTYPE {
  SUBSCRIBE = "subscription",
  TICKET = "ticket",
  GIFT = "gift",
}
export default function CusUpdatePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState<any | AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentProductType, setCurrentProductType] = useState(
    PRODUCTTYPE.SUBSCRIBE
  );
  const [nextPaymentProductName, setNextPaymentProductName] = useState<
    TableProduct[]
  >([]);
  const [currentNextPaymentProductName, setCurrentNextPaymentProductName] =
    useState<TableProduct | null>(null);
  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const TableColumn: ColumnsType<TableProduct> = [
    {
      title: "상품이름",
      dataIndex: "nameKo",
      render: (_, record) => record.name.ko
    },
    {
      title: "가격",
      dataIndex: "price",
    },

    {
      title: "발급날짜",
      dataIndex: "createdAt",
      render: (_, record) => timeConverter(record.createdAt),
      ellipsis: true,
    },
    {
      title: "상품타입",
      dataIndex: "type",
      width: "10%",
      render: (_, record) => {
        if (record.isGift) {
          return <Tag color="orange">선물권</Tag>
        }
        if (record.type === 'subscription') {
          return <Tag color="green">구독권</Tag>;
        }
        return <Tag color="geekblue">이용권</Tag>;
      },
    },
    {
      title: "다음 결제 예약 상품",
      dataIndex: "nextPaymentProductName",
      render: (_, record) =>
        record.isSubscriptionProduct ? record.nextPaymentProductName : "",
    },
  ];

  const handleUpdateTrack = async (formValue: ProductForm) => {
    setLoading(true);
    const { productType, ...resultWithoutProductType } = {
      ...formValue,
      price: parseFloat(formValue.price),
      priceUSD: parseFloat(formValue.priceUSD),
      duration: parseInt(formValue.duration, 10),
      isSubscriptionProduct: formValue.productType === PRODUCTTYPE.SUBSCRIBE,
      isGiftProduct: formValue.productType === PRODUCTTYPE.GIFT,
      nextPaymentProductId:
        currentProductType === PRODUCTTYPE.SUBSCRIBE
          ? currentNextPaymentProductName
            ? currentNextPaymentProductName.id
            : 0
          : 0,
    };

    LPPAXIOS.put(`/admin/product/${id}`, resultWithoutProductType)
      .then((resolve) => {
        api.success({
          message: "B2C 상품 수정을 완료되었습니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.ProductCus.Main.url(), { replace: true });
        }, 500);
      })
      .catch((error) => {
        api.error({
          message: error.response.data.message ?? "B2C 상품 수정 실패",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const onClickBack = () => {
    navigate(LPPUrls.Admin.ProductCus.Main.url());
  };
  const handleOnSearchFilter = (value: string) => {
    if (value.length) {
      LPPAXIOS.get<RESPONSETABLE<TableProduct>>(
        `/admin/product/list?keyword=${value}&isSubscriptionProduct=true`
      )
        .then((resolve) => {
          setNextPaymentProductName(resolve.data.list);
        })
        .catch((error) => {
          api.error({
            message: error.response.data.message ?? "",
          });
        });
    }
    return;
  };

  useEffect(() => {
    LPPAXIOS.get<DetailProduct>(`/admin/product/${id}`)
      .then((resolve) => {
        setCurrentProductType(
          resolve.data.isSubscriptionProduct
            ? PRODUCTTYPE.SUBSCRIBE
            : resolve.data.isGiftProduct
            ? PRODUCTTYPE.GIFT
            : PRODUCTTYPE.TICKET
        );
        // if (resolve.data.nextPaymentProductId === 0) {
        //   setCurrentNextPaymentProductName(null);
        // } else {
        //   LPPAXIOS.get<DetailProduct>(
        //     `/admin/product/${resolve.data.nextPaymentProductId}`
        //   ).then((resolve) => {
        //     setCurrentNextPaymentProductName(resolve.data);
        //   });
        // }

        form.setFieldsValue({
          ...resolve.data,
          nameKo: resolve.data.name.ko,
          nameEn: resolve.data.name.en,
          descriptionKo: resolve.data.description.ko,
          descriptionEn: resolve.data.description.en,
          productType: resolve.data.isGift ?
              PRODUCTTYPE.GIFT
              :
              resolve.data.type === 'subscription' ?
                  PRODUCTTYPE.SUBSCRIBE : PRODUCTTYPE.TICKET,
          price: resolve.data.price.krw,
          priceUSD: resolve.data.price.usd

        });
      })
      .catch((error) => {
        return setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  if (error !== null && error.response.status === 404)
    return (
      <Result
        status={404}
        title={"404"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  if (error !== null && error.response.status === 500)
    return (
      <Result
        status={500}
        title={"500"}
        extra={
          <Button type="primary" onClick={onClickBack}>
            Back Home
          </Button>
        }
      />
    );
  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2C 상품 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ProductCus.Main.url()}>B2C 상품 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>B2C 상품 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Form
          className="flex flex-col gap-2"
          form={form}
          onFinish={handleUpdateTrack}
          initialValues={{ isSubscriptionProduct: true }}
        >
          <Card title="한국어">
            <Form.Item
              name="nameKo"
              label="상품명"
              rules={[
                {
                  required: true,
                  message: "상품명을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="descriptionKo"
              label="상품설명"
              rules={[
                {
                  required: true,
                  message: "상품설명을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
          <Card title="영어">
            <Form.Item
              name="nameEn"
              label="상품명"
              rules={[
                {
                  required: true,
                  message: "상품명을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="descriptionEn"
              label="상품설명"
              rules={[
                {
                  required: true,
                  message: "상품설명을 입력하세요.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Card>
          <Divider />

          <Form.Item name="productType" label="상품 타입">
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                setCurrentProductType(e.target.value);
              }}
            >
              <Radio.Button value={PRODUCTTYPE.SUBSCRIBE}>구독권</Radio.Button>
              <Radio.Button value={PRODUCTTYPE.TICKET}>이용권</Radio.Button>
              <Radio.Button value={PRODUCTTYPE.GIFT}>선물권</Radio.Button>
            </Radio.Group>
          </Form.Item>

          {/* 현재 상품이 구독 상품일때 */}
          {/*{currentProductType === PRODUCTTYPE.SUBSCRIBE &&*/}
          {/*  (currentNextPaymentProductName === null ? (*/}
          {/*    <Button onClick={() => setOpen(true)}>다음 예약 상품 선택</Button>*/}
          {/*  ) : (*/}
          {/*    <>*/}
          {/*      <Card title="다음 예약 결제 상품">*/}
          {/*        <Space direction="vertical">*/}
          {/*          <Typography.Text>*/}
          {/*            <Tag>상품명</Tag> : {currentNextPaymentProductName.nameKo}*/}
          {/*          </Typography.Text>*/}
          {/*          /!*<Typography.Text>*!/*/}
          {/*          /!*  <Tag>상품가격</Tag> :{" "}*!/*/}
          {/*          /!*  {currentNextPaymentProductName.price}원*!/*/}
          {/*          /!*</Typography.Text>*!/*/}
          {/*          <Typography.Text>*/}
          {/*            <Tag>사용기간</Tag> :{" "}*/}
          {/*            {currentNextPaymentProductName.duration}일*/}
          {/*          </Typography.Text>*/}
          {/*        </Space>*/}
          {/*      </Card>*/}
          {/*      <Space>*/}
          {/*        <Button*/}
          {/*          onClick={() => setCurrentNextPaymentProductName(null)}*/}
          {/*        >*/}
          {/*          선택 취소*/}
          {/*        </Button>*/}
          {/*        <Button onClick={() => setOpen(true)}>*/}
          {/*          다음 예약 상품 변경*/}
          {/*        </Button>*/}
          {/*      </Space>*/}
          {/*    </>*/}
          {/*  ))}*/}
          <Divider />
          <Form.Item
            label="상품 이용기간(일)"
            name="duration"
            rules={[
              {
                required: true,
                message: "이용기간을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
              {
                validator: (rule, value) => {
                  const intValue = parseInt(value, 10);
                  if (isNaN(intValue) || intValue < 1) {
                    return Promise.reject(
                      "이용기간은 1일 미만이 될 수 없습니다."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="이용기간(일)" type="text" max={100} />
          </Form.Item>
          <Form.Item
            name="price"
            label="가격"
            rules={[
              {
                required: true,
                message: "가격을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
            ]}
          >
            <Input placeholder="숫자만 입력하세요." />
          </Form.Item>
          <Form.Item
            name="priceUSD"
            label="달러 가격"
            rules={[
              {
                required: true,
                message: "가격을 입력하세요.",
              },
              {
                // 소수점을 포함한 숫자를 허용하는 정규 표현식
                pattern: new RegExp(/^\d+(\.\d+)?$/),
                message: "숫자(소수점 포함)만 입력 가능합니다.",
              },
            ]}
          >
            <Input placeholder="숫자(소수점 포함)만 입력하세요." />
          </Form.Item>

          <Button block htmlType="submit" type="primary">
            상품 수정
          </Button>
        </Form>
        {/*<Modal*/}
        {/*  onOk={() => setOpen(false)}*/}
        {/*  width={900}*/}
        {/*  open={open}*/}
        {/*  onCancel={() => {*/}
        {/*    setOpen(false);*/}
        {/*    setNextPaymentProductName([]);*/}
        {/*  }}*/}
        {/*  closable*/}
        {/*  destroyOnClose*/}
        {/*  title="다음 결제 예약 상품 선택"*/}
        {/*>*/}
        {/*  <Search onSearch={handleOnSearchFilter} />*/}
        {/*  <Table*/}
        {/*    rowKey={(render) => render.id}*/}
        {/*    dataSource={nextPaymentProductName}*/}
        {/*    columns={TableColumn}*/}
        {/*    rowClassName={() => "cursor-pointer"}*/}
        {/*    pagination={false}*/}
        {/*    onRow={(record, _) => {*/}
        {/*      return {*/}
        {/*        onClick: () => {*/}
        {/*          setCurrentNextPaymentProductName(record);*/}
        {/*          setOpen(false);*/}
        {/*        }, // click row*/}
        {/*      };*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Modal>*/}
      </HContent>
    </>
  );
}
