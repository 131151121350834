import { Breadcrumb, Button, Descriptions, Form, Input, Radio } from "antd";

import useNotification from "antd/es/notification/useNotification";

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import Title from "antd/es/typography/Title";

import { LPPAXIOS } from "../../../../framework/api/core.api";
import { LoadingSpin } from "../../../../components/common/Loading";
import Header from "../../../../components/common/Header";
import { LPPUrls } from "../../../../LPPUrls";
import { HContent } from "../../../../components/common/HContent";
import { CollaborateProductDetail } from "./type";
import { timeConverter } from "../../../../utils/timeConverter";

export default function BisUpdatePage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<CollaborateProductDetail>();
  const [loading, setLoading] = useState(true);

  const [api, contextHolder] = useNotification();
  const [form] = Form.useForm();

  const onSubmitCreate = (value: any) => {
    setLoading(true);
    // const enable = value.enable === true ? 1 : 0;
    LPPAXIOS.put(`/admin/third-party/product/${id}`, {
      ...value,
      price: +(value.price),
      thirdPartyId: data?.thirdPartyId,
      enable: value.enable,
    })
      .then((resolve) => {
        api.success({
          message: "B2B 상품이 수정되었습니다.",
          description: "B2B 상품 리스트 페이지로 이동합니다.",
        });
        setTimeout(() => {
          navigate(LPPUrls.Admin.ProductBis.Main.url());
        }, 600);
      })
      .catch((error) => {
        api.error({
          message: "B2B 상품 수정 실패",
          description: error.response.data.message ?? "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    LPPAXIOS.get<CollaborateProductDetail>(`/admin/third-party/product/${id}`)
      .then((resolve) => {
        setData(resolve.data);
        console.log(resolve.data);
        form.setFieldsValue({
          ...resolve.data,
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <>
      {contextHolder}
      <LoadingSpin loading={loading} />
      <Header className="flex-col items-center justify-start py-1 my-1 mb-10 drop-shadow-sm">
        <Title level={4}>B2B 상품 수정</Title>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={LPPUrls.Admin.ProductBis.Main.url()}>B2B 상품 조회</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>협B2B 상품 수정</Breadcrumb.Item>
        </Breadcrumb>
      </Header>
      <HContent>
        <Descriptions>
          <Descriptions.Item label="회사명">
            {data?.thirdPartyName}
          </Descriptions.Item>
          <Descriptions.Item label="이메일">{data?.email}</Descriptions.Item>
          <Descriptions.Item label="연락처">{data?.contact}</Descriptions.Item>
          <Descriptions.Item label="발행일자">
            {data?.createdAt ? timeConverter(data.createdAt) : ""}
          </Descriptions.Item>
        </Descriptions>
        <Form form={form} onFinish={onSubmitCreate}>
          <Form.Item
            label="상품명"
            name="nameKo"
            rules={[
              {
                required: true,
                message: "상품명을 입력하세요.",
              },
            ]}
          >
            <Input placeholder="상품명을 입력하세요." />
          </Form.Item>
          <Form.Item
            label="상품설명"
            name="descriptionKo"
            rules={[
              {
                required: true,
                message: "상품설명을 입력하세요.",
              },
            ]}
          >
            <Input placeholder="상품설명을 입력하세요." />
          </Form.Item>

          <Form.Item
            label="상품가격"
            name="price"
            rules={[
              {
                required: true,
                message: "상품가격을 입력하세요.",
              },
            ]}
          >
            <Input placeholder="숫자만 입력하세요." />
          </Form.Item>
          <Form.Item
            label="구독기간(일)"
            name="duration"
            rules={[
              {
                required: true,
                message: "구독기간(일)을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
              {
                validator: (rule, value) => {
                  const intValue = parseInt(value, 10);
                  if (isNaN(intValue) || intValue < 1) {
                    return Promise.reject(
                      "구독기간은 1일 미만이 될 수 없습니다."
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="숫자만 입력하세요." />
          </Form.Item>
          <Form.Item
            label="사용,등록 가능 기간(일)"
            name="activeDuration"
            rules={[
              {
                required: true,
                message: "사용,등록 가능 기간(일)을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
            ]}
          >
            <Input placeholder="숫자만 입력하세요." />
          </Form.Item>

          <Form.Item
            label="취소,환불 가능 기간(일)"
            name="cancelDuration"
            extra="환불 불가 상품의 경우 0으로 설정하세요."
            rules={[
              {
                required: true,
                message: "취소,환불 가능 기간(일)을 입력하세요.",
              },
              {
                pattern: new RegExp(/^\d+$/),
                message: "숫자만 입력 가능합니다.",
              },
            ]}
          >
            <Input placeholder="숫자만 입력하세요." />
          </Form.Item>
          <Form.Item label="상품상태" name="enable">
            <Radio.Group>
              <Radio value={true}> 활성 </Radio>
              <Radio value={false}> 비활성 </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" type="primary">
              상품 수정
            </Button>
          </Form.Item>
        </Form>
      </HContent>
    </>
  );
}
